import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from '../store';
import { logOut, setAccessToken } from '../../features/auth/authSlice';

// const baseUrl = process.env.REACT_APP_BASE_URL;

declare const window: Window &
   typeof globalThis & {
    REACT_APP_BASE_URL: any
   }
   
const baseUrl = window.REACT_APP_BASE_URL;
console.log("react base: ", baseUrl);

const baseQuery = fetchBaseQuery({
  baseUrl: baseUrl,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).auth.token;
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithReAuth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  if (result?.error?.status === 'PARSING_ERROR' && result?.error?.originalStatus === 403) {
    console.log('Sending refresh token');
    const refreshResult = await baseQuery('/refresh', api, extraOptions);
    console.log('RefreshResult:', refreshResult);
    if (refreshResult?.data) {
      api.dispatch(setAccessToken(refreshResult.data));
      result = await baseQuery(args, api, extraOptions);
    } else {
      api.dispatch(logOut());
    }
  }
  return result;
};

export const apiSlice = createApi({
  baseQuery: baseQueryWithReAuth,
  endpoints: () => ({}),
});
